.homepage-top-level-styling {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	background-image: url("/assets/homepage-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 10px;
}